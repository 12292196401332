<template>
  <v-container fluid class="pa-0 ma-0">
    <keep-alive>
      <component v-if="currentPage === 'listComp'" :is="currentPage"></component>
    </keep-alive>
  </v-container>
</template>

<script>
// !! 독립메뉴 기일기한은 상세뷰페이지가 없이 리스트만 존재한다!
import listComp from '@/views/duedate/list'

export default {
  components: {
    listComp
  },
  data: () => ({
    currentPage: 'listComp'
  }),
  watch: {
    // !!중요: /duedate 내부에서 라우트 변화를 감지하여 동적으로 변경해준다.
    '$route' (to, from) {
      // console.log(to)
      if (to.name === 'duedate' && to.params && to.params.id) {
        // !! 정수인 아이디가 아닌 'LR' 이면 리스트 리프레시 해야한다
        if (to.params.id === 'R' || to.params.id === 'LR') {
          this.switchTo('listComp')
        }
      } else {
        // !! id 가 없으면 리스트로
        this.switchTo('listComp')
      }
    }
  },
  mounted () {
  },
  methods: {
    switchTo (page) {
      this.currentPage = page
    }
  }
}
</script>
